export interface MarketHistoryDataPoint {
  price: number;
  date: string;
}

/** Standard solution Security type codes.*/
export enum SecurityTypeCode {
  EQUITY = "E", //Equity
  ETF = "CE", //Exchange-traded funds (ETFs)
  PRIVATE_EQUITY = "PE", //Private Equity
  DEBT_INSTRUMENT = "D", //Debt instruments
  COLLECTIVE_INVESTMENT_VEHICLE = "C", //Collective investment vehicles
  CURRENCY = "TC", //Currencies
}

/**
 * Security tags indicating whether a security can be bought or sold
 * in units and/or trade amount.
 */
export enum SecurityTradeType {
  buyUnits = "Trade type:Buy units",
  buyTradeAmount = "Trade type:Buy trade amount",
  sellUnits = "Trade type:Sell units",
  sellTradeAmount = "Trade type:Sell trade amount",
}

export interface SecurityDetailsPosition {
  id: number;
  name: string;
  securityCode: string;
  isinCode: string;
  url: string;
  url2: string;
  currency: {
    securityCode: string;
  };
  latestMarketData?: {
    price: number;
    date: string;
  };
  type: {
    code: SecurityTypeCode;
    namesAsMap: Record<string, string>;
    name: string;
  };
  fxRate: number;
  // misnamed on backend, should be tagsAsList
  tagsAsSet: string[];
  documents: {
    fileName: string;
    identifier: string;
    mimeType: string;
  }[];
}

export interface SecurityDetailsQuery {
  security: SecurityDetailsPosition;
}

export interface SecurityMarketDataHistory {
  marketDataHistory: MarketHistoryDataPoint[];
}

export interface SecurityMarketDataHistoryQuery {
  security: SecurityMarketDataHistory;
}

export interface HoldingPosition {
  security: {
    id: number;
  };
  amount: number;
  purchaseTradeAmount: number;
  marketValue: number;
  marketFxRate: number;
}

export interface PortfolioHoldingDetailsQuery {
  portfolio: {
    portfolioReport: {
      holdingPositions: HoldingPosition[];
    };
  };
}
